import {getDownloadUrlPublic} from '@wix/ambassador-events-v2-ticket/http'
import {GetDownloadUrlPublicRequest, GetDownloadUrlPublicResponse} from '@wix/ambassador-events-v2-ticket/types'
import {getSeatingReservationSummary} from '@wix/ambassador-seating-v1-seating-reservation/http'
import {GetSeatingReservationSummaryResponse} from '@wix/ambassador-seating-v1-seating-reservation/types'
import {getActivityStats} from '@wix/ambassador-social-groups-v1-activity-stats/http'
import {GetActivityStatsResponse} from '@wix/ambassador-social-groups-v1-activity-stats/types'
import {listGroupsByEventId} from '@wix/ambassador-social-groups-v1-group-event/http'
import {getGroup} from '@wix/ambassador-social-groups-v2-group/http'
import {GetGroupResponse} from '@wix/ambassador-social-groups-v2-group/types'
import {addQueryParams} from '@wix/panda-js-utils'
import {
  ExperimentNames,
  PageData,
  PlanList,
  TrimmedMember,
  WIX_EVENTS_FQDN,
  defaultLocale,
  draftEventPreviewUrlQueryParamName,
} from '@wix/wix-events-commons-statics'
import {ControllerFlowAPI, ControllerParams} from '@wix/yoshi-flow-editor'
import {GET_SITE_SETTINGS, UPDATE_SITE_SETTINGS} from '../../../../commons/actions/site-settings'
import {SiteSettingsSettings} from '../../../../commons/types/state'
import {Api as BaseApi} from '../../../../commons/utils/api'
import {GET_CHECKOUT_OPTIONS} from '../actions/checkout-options'
import {GET_EVENT, GET_MEMBERS} from '../actions/event'
import {GET_CONNECTED_GROUP, GET_GROUP_ACTIVITY} from '../actions/groups'
import {FETCH_CURRENT_MEMBER, PROMPT_LOGIN} from '../actions/members'
import {PLACE_ORDER, UPDATE_ORDER} from '../actions/placed-order'
import {GET_POLICIES} from '../actions/policies'
import {RESERVE_TICKETS} from '../actions/reservation'
import {DELETE_RSVP, GET_MEMBER_RSVP, SEND_RSVP, UPDATE_RSVP, UPDATE_RSVP_STATUS} from '../actions/rsvp'
import {RegFormData} from '../types/registration'
import {extractFormData} from './api-data-mapper'

export class Api extends BaseApi {
  constructor(controllerParams: ControllerParams) {
    super(controllerParams)

    this.registrar = {
      [GET_EVENT.NAME]: this.getEvent,
      [GET_MEMBERS.NAME]: this.getMembers,
      [GET_CHECKOUT_OPTIONS.NAME]: this.getCheckoutOptions,
      [RESERVE_TICKETS.NAME]: this.makeReservation,
      [GET_MEMBER_RSVP.NAME]: this.getMemberRsvp,
      [SEND_RSVP.NAME]: this.createRsvp,
      [DELETE_RSVP.NAME]: this.deleteRsvp,
      [UPDATE_RSVP.NAME]: this.updateRsvp,
      [PROMPT_LOGIN.NAME]: this.promptLogin,
      [UPDATE_ORDER.NAME]: this.updateOrder,
      [PLACE_ORDER.NAME]: this.placeOrder,
      [FETCH_CURRENT_MEMBER.NAME]: this.getCurrentMember,
      [UPDATE_RSVP_STATUS.NAME]: this.updateRsvpStatus,
      [GET_SITE_SETTINGS.NAME]: this.getSiteSettings,
      [UPDATE_SITE_SETTINGS.NAME]: this.updateSiteSettings,
      [GET_POLICIES.NAME]: this.getPolicies,
      [GET_CONNECTED_GROUP.NAME]: this.getGroup,
      [GET_GROUP_ACTIVITY.NAME]: this.getGroupActivity,
    }
  }

  getData = ({
    slug,
    responsive = false,
    schedulePageInstalled,
    draftPreviewToken,
    flowAPI,
    newClassicEvents,
  }: GetDataArgs) => {
    const encodedSlug = encodeURIComponent(slug)

    if (slug.length > 130) {
      flowAPI.reportError(new Error('Slug is too long'))
      return {} as PageData
    }

    const url = addQueryParams(`/html/page-data/${encodedSlug}`, {
      compId: this.compId,
      locale: this.language,
      viewMode: this.viewMode,
      responsive: String(responsive),
      schedule: String(schedulePageInstalled),
      regional: this.locale,
      [draftEventPreviewUrlQueryParamName]: draftPreviewToken,
      tz: this.tz,
      defaultAlphabetical: flowAPI.experiments.enabled(ExperimentNames.SausageIntegration),
      richContent: !flowAPI.experiments.enabled(ExperimentNames.DisableRicos),
      useRichContentFromEvent: flowAPI.experiments.enabled(ExperimentNames.UseRichContentFromEvent),
      newClassicEvents,
      noComponent: true,
    })

    return this.api.get<PageData>(url).catch(this.logError)
  }

  getOrder = async (
    eventId: string,
    orderNumber: string,
    token: string = '',
    locale: string = defaultLocale,
  ): Promise<{order: wix.events.ticketing.Order; calendarLinks: any; dates: any}> => {
    if (token) {
      // @ts-expect-error
      return this.api.get(`/web/orders/by-token?token=${token}&locale=${locale}`).catch(this.logError)
    } else {
      // @ts-expect-error
      return this.api.get(`/web/events/${eventId}/orders/${orderNumber}?locale=${locale}`).catch(this.logError)
    }
  }

  placeOrder = ({
    eventId,
    buyer,
    guests,
    couponCode,
    giftCardCode,
    memberId,
    planOrderId,
    benefitId,
    locale,
    paymentRedirectUrl,
  }: PlaceOrderArgs): Promise<{order: wix.events.ticketing.Order}> => {
    return this.writeApi
      .post(`/web/events/${eventId}/checkout`, {
        [guests ? 'buyer' : 'data']: extractFormData(buyer),
        guests,
        reservationId: buyer.reservation,
        memberId,
        couponCode,
        giftCardCode,
        planOrderId,
        benefitId,
        locale,
        paymentRedirectUrl,
      })
      .catch(this.logError) as Promise<{order: wix.events.ticketing.Order}>
  }

  updateOrder = ({
    eventId,
    orderNumber,
    buyer,
    guests,
    locale,
    couponCode,
    giftCardCode,
    paidPlanBenefit,
    paymentDetailsId,
    paymentMethod,
  }: UpdateOrderParams): Promise<wix.events.ticketing.UpdateOrderResponse> => {
    return this.writeApi
      .put(`/web/events/${eventId}/checkout/${orderNumber}`, {
        buyer,
        guests: guests?.length ? guests : undefined,
        locale,
        couponCode,
        giftCardCode,
        paidPlanBenefit,
        paymentDetailsId,
        paymentMethod,
      })
      .catch(this.logError)
  }

  getEvent = (eventId: string) => this.api.get(`/web/events/${eventId}/viewer`).catch(this.logError)

  getMembers = (eventId: string): Promise<{members: Member[]; totalGuests: TotalEventGuests}> => {
    return this.api.get(`/web/events/${eventId}/members`).catch(this.logError) as Promise<{
      members: Member[]
      totalGuests: TotalEventGuests
    }>
  }

  getInvoice = (
    eventId: string,
    reservationId: string,
    couponCode?: string,
    giftCardCode?: string,
    benefitId?: string,
    planOrderId?: string,
  ): Promise<wix.events.ticketing.GetInvoiceResponse> => {
    return this.api
      .put(`/web/events/${eventId}/invoice`, {
        reservationId,
        couponCode,
        giftCardCode,
        benefitId,
        planOrderId,
      })
      .catch(this.logError)
  }

  getCheckoutOptions = async (): Promise<wix.events.ticketing.GetCheckoutOptionsResponse> => {
    try {
      const response = await this.api.get('/web/checkout-options')
      return response
    } catch (e) {
      const resolvedError = this.errorHandler.getResolvedError(e)
      console.error(resolvedError)

      return {
        premiumServices: true,
        paymentMethodConfigured: true,
        acceptCoupons: true,
        acceptGiftCards: true,
      }
    }
  }

  makeReservation = (
    eventId: string,
    ticketQuantities: wix.events.ticketing.TicketReservationQuantity[],
  ): Promise<{id: string; expires: string}> => {
    return this.writeApi
      .post(`/web/events/${eventId}/reservations`, {
        ticketQuantities,
        userDateTime: new Date().toISOString(),
      })
      .catch(this.logError) as Promise<{id: string; expires: string}>
  }

  cancelReservation = (eventId: string, reservationId: string): Promise<{}> => {
    return this.writeApi.delete(`/web/events/${eventId}/reservations/${reservationId}`).catch(this.logError)
  }

  getMemberRsvp = async (eventId: string, memberId: string): Promise<wix.events.rsvp.Rsvp> => {
    return this.api
      .get<{rsvp: wix.events.rsvp.Rsvp}>(`/web/events/${eventId}/member-rsvp?memberId=${memberId}`)
      .then(response => response.rsvp)
      .catch(this.logError)
  }

  getCurrentMember = (): Promise<{
    lastName: string
    firstName: string
    loginEmail: string
    id: string
  }> =>
    this.api
      .get<{member: TrimmedMember}>(`/web/members/current`)
      .then(response => response.member)
      .catch(this.logError)

  createRsvp = (
    eventId: string,
    data: RegFormData,
    status: wix.events.rsvp.RsvpStatus,
    memberId?: string,
  ): Promise<wix.events.rsvp.CreateRsvpResponse> => {
    return this.api.post(`/web/events/${eventId}/v2/rsvp`, {memberId, status, data}).catch(this.logError)
  }

  deleteRsvp = (eventId: string, rsvpId: string) =>
    this.api
      .delete(`/web/events/${eventId}/rsvp/${rsvpId}`)
      .then(() => ({eventId}))
      .catch(this.logError)

  updateRsvp = (
    eventId: string,
    data: RegFormData,
    status: wix.events.rsvp.RsvpStatus,
    rsvpId: string,
  ): Promise<wix.events.rsvp.UpdateRsvpResponse> => {
    return this.api.put(`/web/events/${eventId}/rsvp/${rsvpId}/form`, {data, status}).catch(this.logError)
  }

  updateRsvpStatus = (
    eventId: string,
    rsvpId: string,
    status: wix.events.rsvp.RsvpStatus,
  ): Promise<{eventId: string}> => {
    return this.writeApi
      .put(`/web/events/${eventId}/rsvp/${rsvpId}/status`, {status})
      .then(() => ({eventId}))
      .catch(this.logError)
  }

  promptLogin = (lang: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.controller.wixCodeApi.user.promptLogin({
          mode: 'login',
          lang,
        })
        resolve(true)
      } catch (e) {
        const resolvedError = this.errorHandler.getResolvedError(e)
        console.error(resolvedError)

        if (typeof e !== 'string') {
          reject(e)
          return
        }
        resolve(false)
      }
    })
  }

  getPlanList = (eventId: string): Promise<{plans: PlanList}> =>
    this.api.get(`/web/plans?eventId=${eventId}&fixPaidPlans=true`).catch(this.logError) as Promise<{plans: PlanList}>

  getSiteSettings = () => this.writeApi.get('/web/site-settings').catch(this.logError)

  updateSiteSettings = (settings: SiteSettingsSettings) =>
    this.writeApi.put('/web/site-settings', {settings}).catch(this.logError)

  getPolicies = (eventId: string) => this.api.get(`/web/events/${eventId}/policies`).catch(this.logError)

  getGroup = async (eventId: string): Promise<GetGroupResponse> => {
    const {
      data: {groupIds},
    } = await this.httpClient.request(listGroupsByEventId({eventId})).catch(this.logError)

    if (groupIds?.length) {
      return this.httpClient
        .request(getGroup({groupId: groupIds[0]}))
        .then(r => r.data)
        .catch(this.logError)
    }
  }

  getGroupActivity = async (groupId: string): Promise<GetActivityStatsResponse> => {
    return this.httpClient
      .request(getActivityStats({groupId}))
      .then(r => r.data)
      .catch(this.logError) as Promise<GetActivityStatsResponse>
  }

  describeSeatingPlan = async (eventId: string): Promise<GetSeatingReservationSummaryResponse> => {
    return this.httpClient
      .request(
        getSeatingReservationSummary({
          externalId: `${WIX_EVENTS_FQDN}${eventId}`,
        }),
      )
      .then(r => r.data)
      .catch(this.logError)
  }

  getPapyrusTicketsDownloadUrl = async (args: GetDownloadUrlPublicRequest): Promise<GetDownloadUrlPublicResponse> => {
    return this.httpClient
      .request(getDownloadUrlPublic(args))
      .then(response => response.data)
      .catch(this.logError)
  }

  getStaticMapUrl = async (eventId: string, language: string): Promise<string> => {
    return this.api
      .get<string>(addQueryParams(`/html/static-map/${eventId}`, {languageOverride: language}))
      .catch(this.logError)
  }
}

interface PlaceOrderArgs {
  eventId: string
  buyer: RegFormData
  guests: RegFormData[]
  couponCode: string
  giftCardCode: string
  memberId: string
  planOrderId: string
  benefitId: string
  locale: string
  paymentRedirectUrl?: string
}

interface GetDataArgs {
  slug: string
  responsive: boolean
  schedulePageInstalled: boolean
  draftPreviewToken: string
  flowAPI: ControllerFlowAPI
  newClassicEvents?: boolean
}

interface UpdateOrderParams {
  eventId: string
  orderNumber: string
  buyer: RegFormData
  guests: RegFormData[]
  locale: string
  couponCode: string
  giftCardCode: string
  paidPlanBenefit?: {benefitId: string; planOrderId: string}
  paymentDetailsId: string
  paymentMethod?: string
}
